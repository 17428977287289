<template>
  <div v-if="editor">
    <v-toolbar dense>
      <!-- Bold -->
      <v-btn small plain @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>

      <!-- Italic -->
      <v-btn small plain @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>

      <!-- Underline TODO needs extension -->
      <v-btn small plain :disable="true" @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
        <v-icon>mdi-format-underline</v-icon>
      </v-btn>

      <!-- Strike -->
      <v-btn small plain @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <v-icon>mdi-format-strikethrough</v-icon>
      </v-btn>

      <v-divider vertical></v-divider>

      <!-- Color TODO -->
      <v-btn small plain @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <v-icon>mdi-format-color-text</v-icon>
      </v-btn>

      <!-- Font Size TODO -->
      <v-btn small plain @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <v-icon>mdi-format-size</v-icon>
      </v-btn>

      <!-- Haadings -->
      <v-btn small plain @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>

      <v-btn small plain @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>

      <v-btn small plain @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>

      <v-divider vertical></v-divider>

      <!-- Align -->
      <v-btn small plain @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
        <v-icon>mdi-format-align-left</v-icon>
      </v-btn>

      <v-btn small plain @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
        <v-icon>mdi-format-align-center</v-icon>
      </v-btn>

      <v-btn small plain @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
        <v-icon>mdi-format-align-right</v-icon>
      </v-btn>

      <v-btn small plain @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
        <v-icon>mdi-format-align-justify</v-icon>
      </v-btn>

      <v-divider vertical></v-divider>

      <!-- Bullet list -->
      <v-btn small plain @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <v-icon>mdi-format-list-bulleted-square</v-icon>
      </v-btn>

      <!-- Ordered list -->
      <v-btn small plain @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>

      <v-divider vertical></v-divider>

      <!-- Code -->
      <v-btn small plain @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        <v-icon>mdi-xml</v-icon>
      </v-btn>

      <!-- Create table -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            v-bind="attrs"
            v-on="on"
            small plain
            @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
            <v-icon>mdi-table</v-icon>
          </v-btn>
        </template>
        <span>Create table</span>
      </v-tooltip>
    </v-toolbar>

    <!-- Table controls toolbar TODO add tooltips to all -->
    <v-toolbar dense v-show="editor.can().addColumnBefore()">
      <!-- Add column -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  
            v-bind="attrs"
            v-on="on"
            small plain
            :disabled="!editor.can().addColumnBefore()"
            @click="editor.chain().focus().addColumnBefore().run()">
            <v-icon>mdi-table-column-plus-before</v-icon>
          </v-btn>
        </template>
        <span>Add column</span>
      </v-tooltip>

      <v-btn small plain @click="editor.chain().focus().addColumnAfter().run()">
        <v-icon>mdi-table-column-plus-after</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().deleteColumn().run()">
        <v-icon>mdi-table-column-remove</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().addRowBefore().run()">
        <v-icon>mdi-table-row-plus-before</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().addRowAfter().run()">
        <v-icon>mdi-table-row-plus-after</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().deleteRow().run()">
        <v-icon>mdi-table-row-remove</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().deleteTable().run()">
        <v-icon>mdi-table-remove</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().mergeCells().run()">
        <v-icon>mdi-table-merge-cells</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().splitCell().run()">
        <v-icon>mdi-table-split-cell</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().toggleHeaderColumn().run()">
        <v-icon>mdi-page-layout-header</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().toggleHeaderRow().run()">
        <v-icon>mdi-page-layout-header-footer</v-icon>
      </v-btn>
<!--       <v-btn small plain @click="editor.chain().focus().toggleHeaderCell().run()">
        <v-icon>mdi-table-</v-icon>
      </v-btn> -->
<!--       <v-btn small plain @click="editor.chain().focus().mergeOrSplit().run()">
        <v-icon>mdi-table-</v-icon>
      </v-btn> -->
      <v-btn small plain @click="editor.chain().focus().setCellAttribute('colspan', 2).run()">
        <v-icon>mdi-table-column-width</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().fixTables().run()">
        <v-icon>mdi-table-check</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().goToNextCell().run()">
        <v-icon>mdi-table-arrow-right</v-icon>
      </v-btn>
      <v-btn small plain @click="editor.chain().focus().goToPreviousCell().run()">
        <v-icon>mdi-table-arrow-left</v-icon>
      </v-btn>
    </v-toolbar>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Heading from '@tiptap/extension-heading'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'

import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => {
          return {
            backgroundColor: element.getAttribute('data-background-color'),
          }
        },
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
    }
  },
})

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          // Configure an included extension
          heading: {
            levels: [1, 2],
          },
        }),

        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        // Default TableCell
        // TableCell,
        // Custom TableCell with backgroundColor attribute
        CustomTableCell,

        Heading,
        Text,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Underline,
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.v-toolbar.theme--light {
  button.v-btn.is-active {
    background: #eaeaea;
  }
}
.v-toolbar.theme--dark {
  button.v-btn.is-active {
    background: #404040;
  }
}

/* Basic editor styles */
.theme--light .ProseMirror {
  border: 1px solid rgb(118, 118, 118);
  background-color: rgb(255, 255, 255);
}

.theme--dark .ProseMirror {
  border: 1px solid rgb(133, 133, 133);
  background-color: rgb(59, 59, 59);

  table {
    th {
      background-color: #000;
    }
  }
}


.ProseMirror {
  padding: .125rem .25rem;
  font-family: monospace;
  min-height: 300px;

  > * + * {
    margin-top: 0.75em;
  }


  ul,
  ol {
    padding: 0 2rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  /* Table-specific styling */
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}


.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>