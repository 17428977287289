import Vue from 'vue'
import VueRouter from 'vue-router'
import { 
  NotFound,
  Home,
} from '../views/';

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/player-overview/:bop_id', //TODO actual path with BOP id
    name: 'Overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/player/Overview.vue')
  },
  {
    path: '/host-overview/:bop_id', //TODO actual path with BOP id
    name: 'Overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/host/Overview.vue')
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  }
]

const router = new VueRouter({
  //mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes
})


// Check for valid routes from configurations in Vuex store
router.beforeEach((to, from, next) => {
  //console.log(from, " --> ", to, store.state.loggedIn);
  if (store.state.loggedIn) {
    if (to.path == "/") {
      //console.log("Redirected to Home");
      next({ name: 'Home' })
    } else if (routes.map(r => r.name).includes(to.name)) {
      //console.log("Continue");
      next()
    } else {
      //console.log("Couldn't find:", to);
      next({ name: 'NotFound', params: { '0': to.path } })
    }
  } else {
    //console.log("Not logged in yet!");
    if (to.name !== 'Login') {
      //console.log("Redirected to Login");
      next({path: '/login', name: 'Login', params: { '0': to.path } })
    } else {
      //console.log("Continue to login view");
      next()
    }
  }

})

export default router
