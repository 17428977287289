<template>
    <div v-if="mode === 'ck'" class="ck ck-editor preview">
        <div class="ck ck-editor__main">
            <div class="ck ck-content ck-editor__editable_inline" :class="{'ck-editor__editable': showBorder}" dir="ltr" v-html="content">
                <slot />
            </div>
        </div>
    </div>
    <div v-else class="ProseMirror" v-html="content">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'editor-preview',

  props: {
    content: {
        type: String
    },
    showBorder: {
        type: Boolean,
        default: false,
    },
    mode: {
        type: String,
        default: 'tiptap'
    }
  }
}
</script>
