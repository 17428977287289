import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#815127',
                secondary: '#e4d9c3',
                accent: '#926635',
                error: '#b71c1c',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#eae7e0',
                navbar: "#e4d9c3",
                anchor: '#815127',
            },
            dark: {
                primary: '#5C3D2E',
                // secondary: '#e4d9c3',
                // accent: '#926635',
                // error: '#b71c1c',
                // info: '#2196F3',
                // success: '#4CAF50',
                // warning: '#FFC107',
                background: '#2D2424',
                navbar: "#312a2a",
                anchor: '#fff',
            }
        },
    },
});
