<template>
  <v-form v-model="isFormValid">
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            :rules="[rules.required]"
            type="text"
            name="input-user"
            label="Username"
            v-model="inputUsername"
            class="input-group--focused"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPass ? 'text' : 'password'"
            name="input-password"
            label="Password"
            hint="At least 8 characters"
            v-model="inputPassword"
            @click:append="showPass = !showPass"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
          <v-checkbox
            v-model="inputRememberMe"
            :label="`Remember me`"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-btn
          color="primary"
          elevation="2"
          :loading="awaitLogin"
          :disabled="awaitLogin || !isFormValid"
          @click="login"
        >
          Login
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fakeAPI from '../fakeAPI/fakeapi.js';
import axios from 'axios';

export default {
  name: 'LoginView',
  data () {
    return {
      isFormValid: false,
      inputUsername: "",
      inputPassword: "",
      inputRememberMe: false,

      awaitLogin: false, 
      showPass: false,
      password: 'Password',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    }
  },

  methods: {
    login() {
      this.awaitLogin = true; //Shows as loading after clicking
      //call some auth

      // const params = {
      //   username: this.inputUsername,
      //   password: this.inputPassword
      // }

      const data = new FormData();
      data.append('username', this.inputUsername);
      data.append('password', this.inputPassword);
      data.append('rememberme', this.inputRememberMe);

      if (process.env.NODE_ENV != 'development') {
        axios.post('api/login.php', data)
        .then((response) => {
          
          if (response.data.error) {
            console.error(response.data.message);
            this.awaitLogin = false;
            
          } else {
            console.log(response.data);
            this.awaitLogin = false;

            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setUserInfo', response.data);

            this.$router.push('/home')
          }
        })
        .catch(function (error) {
            console.log(error);
        });
      } else {
        //Fake for local debug
        fakeAPI.login().then(result => {
          this.awaitLogin = false;

          this.$store.commit('setLoggedIn', true);
          this.$store.commit('setUserInfo', result);

          this.$router.push('/home')
        }).catch(error => {
          console.log("Failed to login", error);
        })
      }

    }
  },

  created() {
    const data = new FormData();
    data.append('username', '');
    data.append('password', '');

    axios.post('api/login.php', data)
    .then((response) => {
      if (response.data.error) {
        console.warn(response.data.message);
        this.awaitLogin = false;

      } else {
        console.log(response.data);
        this.awaitLogin = false;

        this.$store.commit('setLoggedIn', true);
        this.$store.commit('setUserInfo', response.data);

        this.$router.push('/home')
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }
}

//https://auth0.com/blog/beginner-vuejs-tutorial-with-user-login/#Adding-Authentication-to-your-Vue-App
// possible implementation ^ 
</script>
