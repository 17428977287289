<template>
  <div class="todo">
    <span>Page is TODO</span>
  </div>
</template>

<script>
import fakeAPI from '../../fakeAPI/fakeapi.js';


export default {
    name: 'HostOverview',
    components: {

    },
    data() {
        return {
            bopDataLoaded: false,
            //Panels
            showCC: 0,
            showReport: 0,
            showEditor: 0,
            showPreview: false,

            countryCard: "",

            turnReport: "",

            editorData: '',

            previewConfig: {
                toolbar: [],
            },
        };
    },
    methods: {
        loadHostOverview(bop_id) {
            console.log("Retrieve BoP data for: " + bop_id);
            this.bopDataLoaded = false;

            fakeAPI.getBopData(bop_id).then(result => {
                this.bopDataLoaded = true;
                this.countryCard = result.countryCard;
                this.turnReport = result.turnReport;

            }).catch(error => {
              console.log("Failed to get data for " + bop_id, error);
            })
            //API calls here retrieving all order info
        }
    },
    created() {
        this.loadHostOverview(this.$route.params.bop_id);
    },

    watch: {
      $route(to) {
        this.loadHostOverview(to.params.bop_id);
      }
    }
}
</script>
