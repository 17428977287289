import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkmode: false,
    loggedIn: false,
    userInfo: {
      username: '',
    }
  },
  mutations: {
    setDarkmode(state, darkmode) {
      state.darkmode = darkmode;
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setUserInfo(state, info) {
      console.log('User logged in, info: ', info);
      state.userInfo = {
        username: info.username,
        user_id: info.user_id
      };
    }
  },
  actions: {
  },
  modules: {
  }
})
