<template>
    <div>
        <v-expansion-panels v-model="showCC">
            <v-expansion-panel class="country-card">
                <v-expansion-panel-header>
                    Country Card
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider></v-divider>
                    <EditorPreview v-if="bopDataLoaded" :content="countryCard"></EditorPreview>
                    <v-skeleton-loader v-else
                        type="article"
                    ></v-skeleton-loader>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-divider class="margin"></v-divider>

        <v-expansion-panels v-model="showReport">
            <v-expansion-panel class="turn-report" >
                <v-expansion-panel-header>
                    Turn Report
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider></v-divider>
                    <EditorPreview v-if="bopDataLoaded" :content="turnReport"></EditorPreview>
                    <v-skeleton-loader v-else
                        type="article"
                    ></v-skeleton-loader>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-divider class="margin"></v-divider>

        <v-expansion-panels v-model="showEditor">
            <v-expansion-panel class="editor">
                <v-expansion-panel-header>
                    Editor
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider></v-divider>
                    <TipTap v-if="bopDataLoaded" v-model="editorData" />
                    <v-skeleton-loader v-else
                        type="article, article"
                    ></v-skeleton-loader>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels style="margin-top: 1em;" v-model="showPreview">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Preview
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider></v-divider>
                    <EditorPreview v-if="bopDataLoaded" :content="editorData"></EditorPreview>
                    <v-skeleton-loader v-else
                        type="article"
                    ></v-skeleton-loader>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-btn style="margin-top:20px"
            color="primary"
            elevation="2"
        >Save</v-btn>
    </div>
</template>

<script>
import fakeAPI from '../../fakeAPI/fakeapi.js';

import EditorPreview from '@/components/EditorPreview.vue';
import TipTap from '@/components/TipTap.vue';

export default {
    name: 'PlayerOverview',
    components: {
        EditorPreview,
        TipTap
    },
    data() {
        return {
            bopDataLoaded: false,
            //Panels
            showCC: 0,
            showReport: 0,
            showEditor: 0,
            showPreview: false,

            countryCard: "",

            turnReport: "",

            editorData: '',

            previewConfig: {
                toolbar: [],
            },
        };
    },
    methods: {
        loadPlayerOverview(bop_id) {
            console.log("Retrieve BoP data for: " + bop_id);
            this.bopDataLoaded = false;

            fakeAPI.getBopData(bop_id).then(result => {
                this.bopDataLoaded = true;
                this.countryCard = result.countryCard;
                this.turnReport = result.turnReport;

            }).catch(error => {
              console.log("Failed to get data for " + bop_id, error);
            })
            //API calls here retrieving all order info
        }
    },
    created() {
        this.loadPlayerOverview(this.$route.params.bop_id);
    },

    watch: {
      $route(to) {
        this.loadPlayerOverview(to.params.bop_id);
      }
    }
}
</script>

<style lang="scss">
    .v-divider.margin { margin: 20px 0; }
</style>