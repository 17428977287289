<template>
  <div class="todo">
    <span>Page is TODO</span>
  </div>
</template>

<script>
export default {
  name: 'TODO',
}
</script>
