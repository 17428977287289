<template>
<!-- App.vue -->
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-navigation-drawer :style="{background: $vuetify.theme.themes[theme].navbar}" app v-model="drawer">
      <v-list-item :to="'/home'">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            BoP Tool v2.0
          </v-list-item-title>
          <v-list-item-subtitle>
            Tool of a Fook
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h8">
            Hosting
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="bop in bops.filter(bop => bop.isHost)"
          v-bind:key="bop.displayname"
          link
          :to="bop.path"
        >
          <v-list-item-icon v-if="bop.icon">
            <v-icon>{{ bop.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ bop.displayname }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h8">
            Playing in
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="bop in bops.filter(bop => !bop.isHost)"
          v-bind:key="bop.displayname"
          link
          :to="bop.path"
        >
          <v-list-item-icon v-if="bop.icon">
            <v-icon>{{ bop.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ bop.displayname }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :style="{background: $vuetify.theme.themes[theme].navbar}">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{routeName}}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-switch
        class="darkmode-switch"
        v-model="darkmode"
        inset
        label="Darkmode"
      ></v-switch>

      <v-navigation-drawer
        v-if="$store.state.loggedIn"
        :style="{background: $vuetify.theme.themes[theme].navbar}"
        absolute
        permanent
        right
      >
        <template v-slot:prepend>
          <v-list-item two-line
            @click="showUserDropdown = !showUserDropdown"
          >
            <v-list-item-avatar
              style="justify-content: center;"
              color="primary"
            >
              {{initials}}
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{$store.state.userInfo.username}}</v-list-item-title>
              <v-list-item-subtitle>Logged In</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-navigation-drawer>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-list v-if="showUserDropdown"
        dense
        class="user-dropdown"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-cowboy-hat</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>My profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Change password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- Provides the application the proper gutter -->
      <v-container fluid >

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
  import fakeAPI from './fakeAPI/fakeapi.js';
  import axios from 'axios';

  export default {
    name: "BopTool",
    data: () => ({ 
      drawer: null,
      viewTitle: "Home - BoP Tool",

      showUserDropdown: false,

      bops: [
        // {displayname: "Serva v2.0", path: "/player-overview/1"},
        // {displayname: "Clobe Titan BoP", path: "/player-overview/2"},
        // {displayname: "Fredbop wen", path: "/player-overview/3"},
      ],
    }),
    computed: {
      darkmode: {
        get () {
          return this.$store.state.darkmode
        },
        set (value) {
          this.$vuetify.theme.dark = value;
          this.$store.commit('setDarkmode', value)
        }
      },

      theme(){
        return (this.$vuetify.theme.dark) ? 'dark' : 'light'
      },

      routeName() {
        return this.$router.currentRoute.name;
      },

      initials() {
        return this.$store.state.loggedIn && this.$store.state.userInfo.username?.length > 0 ? this.$store.state.userInfo.username.charAt(0) : "X";
      }
    },
    methods: {
      getBopGames() {
        if (process.env.NODE_ENV != 'development') {
          axios.get('api/game/getGames.php')
          .then((response) => {
              console.log(response.data, this.$store.state.userInfo);
              this.bops = response.data.map(game => {
                const isHost = parseInt(game.game_host) === parseInt(this.$store.state.userInfo.user_id);
                const path = isHost ? `/host-overview/${game.game_ID}` : `/player-overview/${game.game_ID}`;

                return {
                  displayname: game.game_name,
                  isHost,
                  path,
                }
              });
          })
          .catch(function (error) {
              console.log(error);
          });
        } else {
          //Fake for local debug
          fakeAPI.getBops().then(result => {
            this.bops = result.map((game, i) => {
              const isHost = (i === 1);
              const path = isHost ? `/host-overview/${game.id}` : `/player-overview/${game.id}`;

              return {
                displayname: game.displayname,
                isHost,
                path,
              }
            });
          });
        }
      },

      logout() {
        this.$store.commit('setLoggedIn', false);
        this.$store.commit('setUserInfo', {username: ''});

        this.$router.push('/login');
        this.showUserDropdown = false;

        if (process.env.NODE_ENV != 'development') {
          //removes cookies
          axios.post('api/logout.php')
          .catch(function (error) {
              console.log(error);
          });
        }
      }
    },
    created() {
      if (this.$store.state.loggedIn) {
        this.getBopGames();
      }
    },
    watch: {
      '$store.state.loggedIn': {
        handler: function () {
          this.getBopGames();
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
}

.v-main {
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: #e4d9c3;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: #312a2a;
  }

  .user-dropdown {
    z-index: 10;
    position: absolute;
    right: 0px;
    width: 256px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

    &.theme--light {
      background-color: #e4d9c3;
    }

    &.theme--dark {
      background-color: #312a2a;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.darkmode-switch {
    position: relative;
    top: .75rem;
    margin-right: 1.25rem;
}
</style>
