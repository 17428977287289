<template>
  <div class="not-found">
    <span>Sorry, page not found (or no access rights)</span>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>
