function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function login() {
    await timeout(1000);

    return {
        username: "Test Boy",
        user_id: "1"
    };
}

async function getBops() {
    await timeout(1000);
    return [
        {id: 1, displayname: "Testing BoP"},
        {id: 2, displayname: "Serva v2.0 wen"},
        {id: 3, displayname: "Clobe titan BoP"},
    ];
}

async function getBopData(bop_id) {
    await timeout(1000);

    return {
        bop_id,
        bopName: "Test BoP",
        turnName: "Turn 1",
        countryCard: "<p>This is a country card example.</p><p><strong>It has bold.</strong></p><figure class=\"table\"><table><tbody><tr><td>And</td><td>a</td></tr><tr><td>small</td><td>table</td></tr></tbody></table></figure>",
        turnReport: "<p>This is a turn report example.</p><p><em>It has italic.</em></p><figure class=\"table\"><table><tbody><tr><td>And</td><td>a</td></tr><tr><td>small</td><td>table</td></tr></tbody></table></figure>",
    }
}

export default {
    login,
    getBops,
    getBopData,
};
