<template>
  <div class="home">
    <span>Welcome to BoP Tool v2.0! Find the BoPs you have access to in the navigation menu on the left.</span>
<!--     <br><br>
    <span v-if="hasBopUpdates">There's updates for {{bopUpdates.length}} Balance of Power games:</span>
    <ul v-if="hasBopUpdates">
      <li v-for="bopUpdate in bopUpdates" :key="bopUpdate">{{bopUpdate}}</li>
    </ul> -->
  </div>
</template>

<script>
// @ is an alias to /src
//import axios from 'axios';

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      bopUpdates: ['someBoP', 'someOtherBoP']
    }
  },
  computed: {
    hasBopUpdates() {
      return this.bopUpdates.length > 0;
    }
  },

  methods: {
    // getGames() {
    //   axios.get('api/game/getGames.php')
    //   .then((response) => {
    //       console.log(response.data);
    //       this.contactTest = response.data;
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   });
    // },
  },
}
</script>

<style lang="scss" scoped>
</style>